import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import slide1_mobile from '../assets/images/slide-1_mobile.webp';
import slide1_tablet from '../assets/images/slide-1_tablet.webp';
import slide1_desktop from '../assets/images/slide-1_desktop.webp';
import slide2_mobile from '../assets/images/slide-2_mobile.webp';
import slide2_tablet from '../assets/images/slide-2_tablet.webp';
import slide2_desktop from '../assets/images/slide-2_desktop.webp';

const slides = [
    {
        srcMobile: slide1_mobile,
        srcTablet: slide1_tablet,
        srcDesktop: slide1_desktop,
        alt: 'Yorkshire terrier podczas pielęgnacji w naszym salonie groomerskim',
        text: '20% ZNIŻKI NA WSZYSTKIE USŁUGI',
    },
    {
        srcMobile: slide2_mobile,
        srcTablet: slide2_tablet,
        srcDesktop: slide2_desktop,
        alt: 'Pudel na rękach kobiety w naszym salonie groomerskim',
        text: 'WSZYSTKIE SZCZENIĘTA DO 6 MIESIĄCA ŻYCIA RASY OZDOBNE KOMPLEKSOWA OPIEKA -80 zł',
    },
];

const imagesToPreload = [
    slide1_mobile,
    slide1_tablet,
    slide1_desktop,
];

function Slider() {
    useEffect(() => {
        imagesToPreload.forEach(src => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = src;
            document.head.appendChild(link);
        });

        return () => {
            imagesToPreload.forEach(src => {
                const link = document.querySelector(`link[href="${src}"]`);
                if (link) document.head.removeChild(link);
            });
        };
    }, []);

    return (
        <Swiper
            modules={[Pagination, Autoplay, EffectFade]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{ delay: 4000 }}
            effect="fade"
            speed={1000}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className="slide">
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={slide.srcDesktop} />
                            <source media="(min-width: 768px) and (max-width: 1024px)" srcSet={slide.srcTablet} />
                            <source media="(max-width: 767px)" srcSet={slide.srcMobile} />
                            <img src={slide.srcDesktop} alt={slide.alt} className="slide-image" loading="eager" />
                        </picture>
                        <div className="slide-text">
                            {index === 0
                                ? <span className="pink">PRZY PIERWSZEJ WIZYCIE: </span>
                                : <span className="pink">PROMOCJA: </span>}
                            {slide.text}
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default Slider;
