import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import gallery1_mobile from '../assets/images/gallery/gallery-1_mobile.webp';
import gallery1_tablet from '../assets/images/gallery/gallery-1_tablet.webp';
import gallery1_desktop from '../assets/images/gallery/gallery-1_desktop.webp';
import gallery2_mobile from '../assets/images/gallery/gallery-2_mobile.webp';
import gallery2_tablet from '../assets/images/gallery/gallery-2_tablet.webp';
import gallery2_desktop from '../assets/images/gallery/gallery-2_desktop.webp';
import gallery3_mobile from '../assets/images/gallery/gallery-3_mobile.webp';
import gallery3_tablet from '../assets/images/gallery/gallery-3_tablet.webp';
import gallery3_desktop from '../assets/images/gallery/gallery-3_desktop.webp';
import gallery4_mobile from '../assets/images/gallery/gallery-4_mobile.webp';
import gallery4_tablet from '../assets/images/gallery/gallery-4_tablet.webp';
import gallery4_desktop from '../assets/images/gallery/gallery-4_desktop.webp';

const images = [
    {
        mobile: gallery1_mobile,
        tablet: gallery1_tablet,
        desktop: gallery1_desktop,
    },
    {
        mobile: gallery2_mobile,
        tablet: gallery2_tablet,
        desktop: gallery2_desktop,
    },
    {
        mobile: gallery3_mobile,
        tablet: gallery3_tablet,
        desktop: gallery3_desktop,
    },
    {
        mobile: gallery4_mobile,
        tablet: gallery4_tablet,
        desktop: gallery4_desktop,
    },
];

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const openSwiper = index => {
        setSelectedIndex(index);
        setIsOpen(true);
    };

    const closeSwiper = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Escape') {
                closeSwiper();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        if (isOpen) {
            document.body.classList.add('overlay');
        } else {
            document.body.classList.remove('overlay');
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.classList.remove('overlay');
        };
    }, [isOpen]);

    return (
        <section className="gallery" id="gallery">
            <div className="gallery-title">
                <div className="gallery-title-wrapper container">
                    <h2>GALERIA</h2>
                    <button
                        onClick={() => openSwiper(0)}
                        className="gallery-button"
                        type="button"
                        title="Zobacz wszystko w galerii"
                    >
                        Zobacz Wszystko
                    </button>
                </div>
            </div>

            <div className="gallery-wrapper">
                {images.map((image, index) => (
                    <div className="gallery-item" key={index} onClick={() => openSwiper(index)}>
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={image.tablet} />
                            <source media="(min-width: 768px) and (max-width: 1024px)" srcSet={image.tablet} />
                            <source media="(max-width: 767px)" srcSet={image.mobile} />
                            <img src={image.tablet} alt={`Zdjęcie z naszego salonu groomerskiego ${index + 1}`} className="gallery-image" loading="lazy" />
                        </picture>
                    </div>
                ))}
            </div>

            {isOpen && (
                <div className="swiper-popup">
                    <div className="swiper-popup-body">
                        <button className="close-button" onClick={closeSwiper} type="button" title="Zamknij okno">
                            <FaTimes size={24} color="#ffffff" />
                        </button>
                        <div className="swiper-popup-content">
                            <Swiper
                                modules={[Navigation]}
                                navigation
                                slidesPerView={1}
                                initialSlide={selectedIndex}
                                loop={true}
                                className="swiper-container"
                            >
                                {images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <picture>
                                            <source media="(min-width: 1025px)" srcSet={image.desktop} />
                                            <source media="(min-width: 768px) and (max-width: 1024px)" srcSet={image.tablet} />
                                            <source media="(max-width: 767px)" srcSet={image.tablet} />
                                            <img src={image.desktop} alt={`Zdjęcie z naszego salonu groomerskiego ${index + 1}`} className="swiper-popup-image" loading="eager" />
                                        </picture>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            )}

            {isOpen && <div className="swiper-overlay" onClick={closeSwiper}></div>}
        </section>
    );
};

export default Gallery;
