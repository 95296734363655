import video from '../assets/video/about-video.mp4';

function About() {
    return (
        <section className="about" id="about">
            <div className="about-wrapper container">
                <div className="about-video-wrapper">
                    <video className="about-video" controls autoPlay loop muted>
                        <source src={video} type="video/mp4" />
                        Twoja przeglądarka nie obsługuje tagu wideo.
                    </video>
                </div>
                <div className="about-paragraph">
                    <h2 className="about-title">O NAS</h2>
                    <p className="about-text">
                        Witamy w naszym studio urody dla zwierząt Łapki Grooming!
                        Nasz profesjonalny personel z przyjemnością dostosuje fryzurę, którą Ty i Twój czworonożny pupil pokochacie!
                        Naszym priorytetem jest jakość i bezpieczeństwo.
                        Wybieramy markowe kosmetyki i produkty higieniczne!
                    </p>
                </div>
            </div>
        </section>
    );
}

export default About;
