import { useState, useEffect, useRef } from 'react';
import { FaMapMarkerAlt, FaClock, FaPhone } from 'react-icons/fa';

import logo from '../assets/images/logo.webp';

const pink = '#ff99cc';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const navRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (navRef.current && !navRef.current.contains(event.target)
                    && !(event.target.classList.contains('bar') ||  event.target.classList.contains('hamburger'))) {
                closeMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        if (isOpen) {
            document.body.classList.add('overlay');
        } else {
            document.body.classList.remove('overlay');
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.classList.remove('overlay');
        };
    }, [navRef, isOpen]);

    return (
        <header className="header">
            <div className="header-top container">
                <div className="header-top-item logo">
                    <a href="https://www.lapkigrooming.pl" title="Salon groomerski Łapki Grooming">
                        <img src={logo} alt="Logo firmy Łapki Grooming" loading="eager" />
                    </a>
                </div>
                <div className="header-top-item address-info">
                    <div className="address">
                        <a
                            href="https://www.google.com/maps/search/?api=1&query=Biskupice+2,+32-200,+Miechów"
                            className="header-top-link"
                            title="Zobacz naszą lokalizację na Google Maps"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaMapMarkerAlt size={18} color={pink} />
                            <span className="header-top-text">Nasz adres: Biskupice 2, 32-200, Miechów</span>
                        </a>
                    </div>
                    <div className="working-hours">
                        <FaClock size={18} color={pink} />
                        <span className="header-top-text">Godziny otwarcia: pon-sob | 09:00-21:00</span>
                    </div>
                </div>
                <div className="header-top-item contact-info">
                    <div className="phone">
                        <a
                            href="tel:+48781752189"
                            className="header-top-link"
                            title="Zadzwoń do nas teraz"
                        >
                            <FaPhone size={18} color={pink} />
                            <span className="header-top-text">(+48) 781 752 189</span>
                        </a>
                    </div>
                    {/* <div className="email">
                        <FaEnvelope size={18} color={pink} />
                        <span className="header-top-text"></span>
                    </div> */}
                </div>
            </div>

            <nav className="navigation container">
                <ul className="navigation-list">
                    <li className="navigation-item">
                        <a
                            href="#services"
                            className="navigation-item-link"
                            title="Zobacz nasze usługi"
                        >
                            Usługi
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            href="#about"
                            className="navigation-item-link"
                            title="Dowiedz się więcej o naszej salon"
                        >
                            O Nas
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            href="#gallery"
                            className="navigation-item-link"
                            title="Przeglądaj naszą galerię zdjęć"
                        >
                            Galeria
                        </a>
                    </li>
                    <li className="navigation-item">
                        <a
                            href="#contacts"
                            className="navigation-item-link"
                            title="Skontaktuj się z nami"
                        >
                            Kontakt
                        </a>
                    </li>
                </ul>
            </nav>

            <div className="header-top-hamburger">
                <div className="header-top-item logo">
                    <a href="https://www.lapkigrooming.pl" title="Salon groomerski Łapki Grooming">
                        <img src={logo} alt="Logo firmy Łapki Grooming" />
                    </a>
                </div>

                <button className="hamburger" onClick={toggleMenu} type="button" title="Menu główne">
                    <span className={`bar ${isOpen ? 'open' : ''}`}></span>
                    <span className={`bar ${isOpen ? 'open' : ''}`}></span>
                    <span className={`bar ${isOpen ? 'open' : ''}`}></span>
                </button>

                <nav className={`navigation-hamburger ${isOpen ? 'open' : ''}`} ref={navRef}>
                    <ul className="navigation-hamburger-list">
                        <li className="navigation-hamburger-item">
                            <a
                                href="#services"
                                onClick={closeMenu}
                                className="navigation-hamburger-item-link"
                                title="Zobacz nasze usługi"
                            >
                                Usługi
                            </a>
                        </li>
                        <li className="navigation-hamburger-item">
                            <a
                                href="#about"
                                onClick={closeMenu}
                                className="navigation-hamburger-item-link"
                                title="Dowiedz się więcej o naszej salon"
                            >
                                O Nas
                            </a>
                        </li>
                        <li className="navigation-hamburger-item">
                            <a
                                href="#gallery"
                                onClick={closeMenu}
                                className="navigation-hamburger-item-link"
                                title="Przeglądaj naszą galerię zdjęć"
                            >
                                Galeria
                            </a>
                        </li>
                        <li className="navigation-hamburger-item">
                            <a
                                href="#contacts"
                                onClick={closeMenu}
                                className="navigation-hamburger-item-link"
                                title="Skontaktuj się z nami"
                            >
                                Kontakt
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            {isOpen && <div className="swiper-overlay" onClick={closeMenu}></div>}
        </header>
    );
}

export default Header;