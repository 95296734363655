import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";

const center = {
    lat: 50.3353013,
    lng: 19.9775686,
};

const MapComponent = () => {
    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
			<div className="map">
				<Map defaultZoom={10} defaultCenter={center} mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}>
					<AdvancedMarker position={center}></AdvancedMarker>
				</Map>
			</div>
      	</APIProvider>
    );
};

export default MapComponent;
