import Slider from '../components/Slider';
import Hero from '../components/Hero';
import Services from '../components/Services';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Contacts from '../components/Contacts';

function Home() {
    return (
        <div className="home">
            <Slider />
            <Hero />
            <Services />
            <About />
            <Gallery />
            <Contacts />
        </div>
    );
}

export default Home;
