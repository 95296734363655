import hero1 from '../assets/images/hero-1.webp';
import hero2 from '../assets/images/hero-2.webp';
import hero3 from '../assets/images/hero-3.webp';

const items = [
    {
        src: hero1,
        alt: 'Ikona trofeum reprezentująca profesjonalnych specjalistów',
        text: 'Profesjonalni specjaliści',
    },
    {
        src: hero2,
        alt: 'Ikona certyfikatu reprezentująca warsztaty i szkolenia z groomingu',
        text: 'Warsztaty i szkolenia z groomingu',
    },
    {
        src: hero3,
        alt: 'Ikona narzędzi do pielęgnacji reprezentująca akcesoria i ubrania dla psów',
        text: 'Akcesoria i ubrania dla psów',
    },
];

function Hero() {
    return (
        <section className="hero-banner">
            <ul className='hero-banner-list container'>
                {items.map((item, index) => (
                    <li className='hero-banner-item' key={index}>
                        <img src={item.src} alt={item.alt} className="hero-banner-image" loading="eager" />
                        <p className='hero-banner-text'>{item.text}</p>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Hero;
