import { FaCheck } from 'react-icons/fa';

import services_mobile from '../assets/images/services_mobile.webp';
import services_tablet from '../assets/images/services_tablet.webp';

const pink = '#ff99cc';
const services = [
    {
        title: 'Strzyżenie psów',
        text: 'Strzyżenie psa jest dostosowane do życzeń każdego klienta i zgodnie ze standardem danej rasy'
    },
    {
        title: 'Obcinanie pazurków, Pielęgnacja oczu i uszu',
        text: 'Na każdej wizycie wykonujemy kompleksową pielęgnację oczu i uszu (usuwanie sierści z uszu, czyszczenie okolic oczu) oraz obcinanie pazurów specjalnymi narzędziami'
    },
    {
        title: 'Kąpiel i czesanie',
        text: 'Do mycia używamy wysokiej jakości kosmetyków, które są całkowicie bezpieczne dla zwierząt. Produkty dobierane są indywidualnie dla każdego pupila w zależności od rodzaju sierści. Wyczesywanie pozwala pozbyć się kołtunów i ogranicza wypadanie sierści, dzięki czemu pupil nie zostawia jej w całym domu'
    },
];

function Services() {
    return (
        <section className="services" id="services">
            <div className="services-wrapper container">
                <h1 className="services-title">Salon groomerski Miechów</h1>

                <div className="services-paragraph">
                    <h2 className="services-sub-title">NASZE USŁUGI</h2>
                    <p className="services-text">
                        Oferujemy szeroki zakres usług pielęgnacji i strzyżenia zwierząt domowych:
                    </p>
                </div>

                <div className="services-content">
                    <div className="services-image-wrapper">
                        <picture>
                            <source media="(min-width: 1025px)" srcSet={services_tablet} />
                            <source media="(min-width: 768px) and (max-width: 1024px)" srcSet={services_tablet}  />
                            <source media="(max-width: 767px)" srcSet={services_mobile} />
                            <img src={services_tablet} alt="Grupa różnych psów razem" className="services-image" loading="eager" />
                        </picture>
                    </div>

                    <ul className="services-list">
                        {services.map((service, index) => (
                            <li className="services-item" key={index}>
                                <span className="services-checkmark">
                                    <FaCheck size={18} color={pink} />
                                </span>
                                <span className="services-text">
                                    <span className="services-title-small">{service.title}</span>
                                    <span className="services-text-small">{service.text}</span>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Services;
