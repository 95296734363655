import { FaFacebook, FaInstagram, FaWhatsapp, FaTiktok } from 'react-icons/fa';

import logo from '../assets/images/logo.webp';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-wrapper container">
                <div className="footer-company">
                    <span>&copy; Firma "Łapki Grooming" 2024.</span>
                    <span>Wszelkie prawa zastrzeżone.</span>
                </div>

                <div className="footer-logo">
                    <a href="https://www.lapkigrooming.pl" title="Salon groomerski Łapki Grooming">
                        <img src={logo} alt="Logo firmy Łapki Grooming" loading="lazy" />
                    </a>
                </div>

                <div className="footer-socials">
                    <span className="footer-socials-item">
                        <a href="https://wa.me/48781752189" title="Porozmawiaj z nami przez WhatsApp" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp size={26} color="#25D366" />
                        </a>
                    </span>
                    <span className="footer-socials-item">
                        <a href="https://www.instagram.com" title="Zobacz nasze zdjęcia na Instagramie" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={26} color="#E1306C" />
                        </a>
                    </span>
                    <span className="footer-socials-item">
                        <a href="https://www.facebook.com" title="Odwiedź naszą stronę na Facebooku" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={26} color="#3b5998" />
                        </a>
                    </span>
                    <span className="footer-socials-item">
                        <a href="https://www.tiktok.com" title="Obejrzyj nasze filmy na TikToku" target="_blank" rel="noopener noreferrer">
                            <FaTiktok size={26} color="#000000" />
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
